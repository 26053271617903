import './App.css';
import { BrowserRouter, Routes, Route, useParams, useLocation } from "react-router-dom";
import LandingWrapper from "./layout/landingLayout/landingWrapper";
import DashboardWrapper from './layout/dashboardLayout/dashboardWrapper';
import { useRef } from 'react';
import Homepage from "./pages/homepage";
import AuthRoutes from './routes/AuthRoutes'
import Dashboard from './pages/dasboard';
import ProtectedRoute from './utils/private';
import Transaction from './pages/dasboard/transactions';
import FundWallet from './pages/dasboard/fund';
import PaymentMethod from './pages/dasboard/fund/payment-method';
import FundRoutes from './routes/FundRoutes';
import Account from './pages/dasboard/account';
import ProfileSettings from './pages/dasboard/settings';
import Referral from './pages/dasboard/referral';
import Support from './pages/dasboard/support'
import Notification from './pages/dasboard/notification';
import EditProfile from './pages/dasboard/edit-profile';
import UtilsRoutes from './routes/UtilsRoutes';
import NotFound from './pages/404';
import AboutUs from './pages/about';
import PrivacyPolicy from './pages/privacy';
import ComingSoon from './pages/coming-soon';
import SuccessPage from './pages/success';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import TaskRoutes from './routes/TaskRoutes';
import ChangePassword from './pages/dasboard/settings/changePassword';



function App() {
  // const urlParams = useParams()
  const tawkMessengerRef = useRef();

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<LandingWrapper />}>
            <Route index element={<Homepage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            {/* ======= authentication pages ==========  */}
            <Route path="/auth/*" element={<AuthRoutes />} />
            <Route path='/coming-soon' element={<ComingSoon />} />
            <Route path="*" element={<NotFound />} />
          </Route>

          {/* ==== Dashbaord pages ====== */}
          <Route path='/dashboard' element={<DashboardWrapper/>}>
            <Route index element={<Dashboard />} />
            <Route path='/dashboard/transactions' element={<Transaction />} />
          </Route>

          <Route>
            <Route path='/dashboard/success' element={<SuccessPage />} />
          {/* ========= utilities page =======  */}
            <Route path='/dashboard/utilities/*' element={<UtilsRoutes />} />
          {/* ========= task page =======  */}
          <Route path='/dashboard/tasks/*' element={<TaskRoutes />} />
          {/* ========= profile / settings / support page =======  */}
            <Route path='/dashboard/my-account' element={<Account />}/>
            <Route path='/dashboard/settings' element={<ProfileSettings />} />
            <Route path='/dashboard/settings/change-password' element={<ChangePassword />} />
            <Route path='/dashboard/referral' element={<Referral />} />
            <Route path='/dashboard/support' element={<Support />} />
            <Route path='/dashboard/notifications' element={<Notification />} />
            <Route path='/dashboard/edit-profile' element={<EditProfile />} />
            <Route path='/dashboard/fund-wallet' element={<FundWallet/>}/>
            <Route path='/dashboard/fund-wallet/payment-method' element={<PaymentMethod/>}/>
            <Route path='/dashboard/fund-wallet/*' element={<FundRoutes />}/>
          </Route>
        </Routes>
      </BrowserRouter>
      <TawkMessengerReact
        propertyId="6552257bcec6a912820f6039"
        widgetId="1hf4e4r9k"
        ref={tawkMessengerRef}
        userId="YOUR_USER_ID"
      />
      </>
  );
}

export default App;
