import React, {useState, useEffect} from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import SubNav from '../../../components/sub-nav'
import { ussd, codeRed } from '../../../assets/dashboard'

import { Flex, Text, Container, Image, Link } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { colors } from '../../../styles/theme'
import Cookies from 'js-cookie'


const USSD = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dataToReceive = state && state.data;

  // Use dataToReceive in your component
  // // console.log(dataToReceive);

  
  return (
    <Wrapper>
        <SubNav title={'USSD'} />
        <Container 
          maxW={{ base: 'sm', lg: 'lg'}}
          my='2rem'
        >
            <Flex my='1rem' justify={'center'} alignItems='center' gap={'1.2rem'} fontSize={{ base: '24px', lg: '32px' }} fontWeight='500' lineHeight={'33px'} color='#FB5167'>
                <Image src={codeRed} alt='icon' w={{ base: '30px', lg: '40px' }} />USSD
            </Flex>

            <div>
                <label>Amount</label>
                <InputContainer>
                    <h3>₦</h3>
                    <input type='text' style={{ color: '#FB5167' }} placeholder='6,000' value={dataToReceive.amount} readOnly />
                </InputContainer>
            </div>
            <Text textAlign='center' fontSize={'15px'} fontFamily='raleway'>Depending on your bank, dial any related short code<br /> below on your phone</Text>

            {dataToReceive.ussd_array.map((item, index) => (
                <Flex key={index} 
                    textAlign='start'
                    fontSize={'16px'}
                    borderRadius='16px' 
                    p='1rem 1.5rem' 
                    my='.8rem'
                    w={{ base: '100%', lg: '400px'}} 
                    bg={`${colors.lightBg}`} 
                    color={`${colors.primaryColor}`}
                >
                    <Text color='#FB5167'>{index}</Text>. <span style={{ marginRight: '.5rem'}}>{item.name}:</span> <a href={`tel:${item.ussdTemplate}`}>{item.ussdTemplate}</a>
                </Flex>
            ))}
        </Container>
    </Wrapper>
  )
}

export default USSD


const Wrapper = styled.div`
    background: #F3F3F3;
    /* padding: 1rem 0; */
    padding-bottom: 4rem;
`;

const InputContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    
    border-radius: 12px;
    background: ${colors.lightBg};
    box-shadow: 0px 4px 120px 0px rgba(127, 135, 158, 0.10);
    
    padding: 0 10px;
    height: 60px;
    margin-bottom: 1rem;
  
  h3 {
    background: rgba(66, 164, 217, .16);
    color: ${colors.primaryColor};
    border-radius: 15px;
    /* width: 55px; */
    margin-right: .6rem;
    /* opacity: 0.16; */
    padding: 10px 18px;

    @media (max-width: 992px) {
      padding: 9px 12px;
    }

  }
  
  input {
    background: transparent;
  }
  input:focus {
    border: none;
    outline: none;
  }
`;
