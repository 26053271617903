import React from 'react'

// assets imports & Styling
import { MainWrapper, MainHero, HeroContent, Newsletter, SideHero} from '../../styles/home.style'

import {colors} from "../../styles/theme";

import { Flex } from '@chakra-ui/react';
import {FaUserCircle} from "react-icons/fa";
import { MdArrowForward } from 'react-icons/md';
// import Navigation from './navigation';
import background from "../../assets/background.svg";

const Homepage = () => {

  return (
    <>
        {/* <Navigation /> */}
        <MainWrapper>
            {/*   =========== hero section ==========     */}
            <MainHero>
                <HeroContent>
                    <h2>Simplifying Your <span style={{ color: `${colors.primaryColor}`}}>Bill Payments</span></h2>
                    <p>On Suta, we simplify how you pay bills into a single, intuitive platform that saves you time, effort, and stress.</p>

                    
                </HeroContent>

                {/*    ============ newsletter form ========= */}
                <Newsletter>
                    <h3>Register/Login Your Account</h3>

                    {/* ----- form input ---- */}
                    <div className={'input-container'}>
                        <Flex
                            justifyContent={'start'}
                            alignItems={'center'}
                            gap={'5px'}
                        >
                            <FaUserCircle />
                            <input type={'text'} placeholder={'081++++++++++'}/>
                        </Flex>

                        <a href='/auth/register'>
                            <span className='mobile-text'>
                                Get started
                            </span>
                            <span className="mobile-icon">
                                <MdArrowForward style={{ color: "#fff", fontSize: "24px" }} />
                            </span>
                        </a>
                        {/* <button>
                        </button> */}
                    </div>
                </Newsletter>
            </MainHero>

            {/*  ============== side hero section =========== */}
            <SideHero>
                <img src={background} alt='background' />
            </SideHero>
        </MainWrapper>
    </>
  )
}

export default Homepage;