import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

// styling and assets
import { Container, Wrapper, InputContainer, SubmitBtn, Help, Form } from '../../styles/login.style'
import { Flex, InputGroup, Button, Input, InputRightElement, Spinner } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { lock, email, } from '../../assets/auth';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { useToast } from '@chakra-ui/react';
import { decrement, increment } from '../../store/reducers/loading';

const Login = () => {
  const loadingCount = useSelector((state) => state.loading.count);
  const dispatch = useDispatch();
  const isLoading = () => {


    return loadingCount > 0;
  }
  const [userInput, setUserInput] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [password, setPassword] = useState("");
  const { user } = useSelector((store) => store.user);
  const navigate = useNavigate();
  const toast = useToast()

  const [showPassword, setShowPassword] = useState(false)

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setUserInput(newValue);
  };

  const handleBlur = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const phoneRegex = /^[0-9]{11}$/;

    if (emailRegex.test(userInput) || phoneRegex.test(userInput)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const isEmail = (email) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!userInput && !password) {
      return toast.error("please provide all values");
    }

    try {
      dispatch(increment());
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: isEmail(userInput) ? userInput : undefined,
          phone_number: !isEmail(userInput) ? userInput : undefined,
          password: password,
        }), // Convert the form data to JSON
      });

      const data = await response.json();
      console.log(data)
      if (response.status == 401) {
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
      }
      dispatch(decrement());
      const token = data.data.token

      Cookies.set('Token', token, { expires: 1 });
      localStorage.setItem('token', token);


      navigate("/dashboard");


    } catch (err) {
      dispatch(decrement());
      console.log(err)
      toast({
        title: err.message,
        status: 'error',
        description: "",
        isClosable: true,
      })
      // Handle errors
      // console.log(err)
    }

    // // console.log(user);
  };


  const isAuthenticated = async () => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/me`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });
      dispatch(decrement());
      const data = await response.json();

      if (data.status) {
        navigate("/dashboard");
      }



    } catch (err) {


    }
  }

  useEffect(() => {
    isAuthenticated();


  }, []);


  return (
    <Container>
      <Wrapper>
        <Flex
          w={{ base: '100%', lg: '500px' }}
          justifyContent={'start'}
          alignItems={'start'}
          flexDir={'column'}
          margin={'5px auto'}
          gap={'8px'}
          textTransform='capitalize'
        >
          <h2>sign in your Account</h2>
          <p>Welcome back!</p>
        </Flex>

        <Form onSubmit={handleSubmit}>
          <InputContainer>
            <img src={email} alt='email-icon' />
            <input
              type="text"
              placeholder="Email or Phone Number"
              value={userInput}
              onChange={handleInputChange}
              // onBlur={handleBlur}
              required
            />
          </InputContainer>
          {!isValid && <p className="error">Invalid input. Please enter a valid email or phone number.</p>}

          <InputContainer>
            <img src={lock} alt='lock-icon' />

            <InputGroup p={0} m={0}>
              <Input
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
                _focus={{
                  border: 'none',
                  background: 'transparent',
                  outline: 'none'
                }}
                variant='unstyled'
                required />
              <InputRightElement h={'full'}>
                <Button
                  variant={'ghost'}
                  _focus={{
                    border: 'none',
                    outline: 'none',
                    background: 'transparent'
                  }}
                  _hover={{
                    background: 'transparent'
                  }}
                  onClick={() => setShowPassword((showPassword) => !showPassword)}>
                  {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>
          </InputContainer>

          <Help>
            <div id="terms">
              <input type="checkbox" />
              <p>Remember me</p>
            </div>
            <Link to="/auth/forget-password">Forgot Password?</Link>
          </Help>
          {isLoading() ?
            <div className='icon-wrapper'  >
              <SubmitBtn>
                <Spinner />
              </SubmitBtn>
            </div>
            :
            <SubmitBtn type="submit">
              Log In
            </SubmitBtn>
          }
          <p id="already-user">
            You don't have an account? <Link to="/auth/register">Sign Up</Link>
          </p>
        </Form>
      </Wrapper>
    </Container>
  )
}

export default Login