import React, { useEffect, useState } from 'react'
import SubNav from '../../../components/sub-nav';
import { Container, Heading, Select, Stack, Button, Input, Spinner, InputRightElement, InputGroup } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { user } from '../../../assets/auth';
import { colors } from '../../../styles/theme';
import { styled } from 'styled-components';
import { BsArrowRight } from 'react-icons/bs'
import { naira } from '../../../assets/dashboard/utils';
import Cookies from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux';
import { decrement, increment } from '../../../store/reducers/loading';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import SearchableDropdown from '../../../components/SearchableDropdown';

const Airtime = () => {
  const loadingCount = useSelector((state) => state.loading.count);
  const dispatch = useDispatch();
  const isLoading = () => {
    // console.log(loadingCount);
    return loadingCount > 0;
  }
  const navigate = useNavigate();
  const toast = useToast()
  const [amount, setAmount] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [password, setPassword] = useState('')
  const [banks, setBanks] = useState([]);
  const [bankCode, setBankCode] = useState(null)
  const [reference, setReference] = useState('')
  const [description, setDescription] = useState('')
  const [accountName, setAccountName] = useState('')
  const [verified, setVerified] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const handleGetBanks = async () => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/bank/get_banks`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      setBanks(data.data.map(x => ({value: x.code, label: x.name}) ))


    } catch (err) {
      dispatch(decrement());
      // console.log(err)
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
    }
  }

  const handleVerifyAccountNumber = async (event) => {
    event.preventDefault();

    if (!accountNumber && !bankCode) {
      return toast.error("please provide all values");
    }

    const token = `Bearer ${Cookies.get('Token')}`
    try {
      dispatch(increment());
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/bank/transfer/verify_account`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          bank_code: bankCode,
          account_number: accountNumber,
        }),
      });

      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      if (!data.status) {
        // console.log(data.status)
        toast({
          title: "An error occurred",
          status: 'error',
          description: data?.message,
          isClosable: true,
        })
      } else {
        // console.log(data.data);
        setAccountName(data.data.account_name);
        setReference(data.data.reference);
        setVerified(true);
      }
    } catch (err) {
      dispatch(decrement());
      toast({
        title: "An error occurred",
        status: 'error',
        description: 'Network Error',
        isClosable: true,
      })
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
      // console.log(err)
    }


  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!accountNumber && !password && !amount) {
      return toast.error("please provide all values");
    }
    const token = `Bearer ${Cookies.get('Token')}`
    try {
      dispatch(increment());
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/bank/transfer/send_fund`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          reference: reference,
          description: description,
          amount: amount,
          password: password,
        }),
      });

      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      if (!data.status) {
        // console.log(data.status)
        toast({
          title: "An error occurred",
          status: 'error',
          description: data?.message,
          isClosable: true,
        })
      } else {
        const msgToPass = "You transfer was successful!"
        navigate("/dashboard/success", { state: { type: 'transfer', data: msgToPass, transaction: data.data } });

      }
    } catch (err) {
      dispatch(decrement());
      // Handle errors
      // console.log(err)
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
    }

    // // console.log(user);
  };

  useEffect(() => {
    handleGetBanks();
  }, []);


  return (
    <div>
      <Wrapper>
        <SubNav title={'Bank Transfer'} />
        <Container maxW={{ base: 'sm', md: 'md', lg: 'lg' }} my='2rem'>
          <Heading fontSize={'24px'} fontWeight='700' fontFamily={'inter'}>Transfer to Account</Heading>

          <form onSubmit={handleSubmit}>

            <label>Account Number</label>
            <InputContainer style={{ margin: '1.2rem 0' }}>
              <input
                style={{"flex": "1"}}
                type="text"
                value={accountNumber}
                onChange={(e) => { setVerified(false); setAccountNumber(e.target.value) }}
                placeholder='0090******'
              />
            </InputContainer>

            <label>Select Bank</label>
            <InputContainer style={{ margin: '1.2rem 0' }}>
              <SearchableDropdown options={banks} onSelect={(value) => { setVerified(false); setBankCode(value)} } defaultText={'Select Bank'} value={bankCode} />
            </InputContainer>

            {!verified &&
              <Stack spacing={10} pt={2}>
                {isLoading() ?
                  <Button
                    loadingText="Loading"
                    size="lg"
                    type='submit'
                    bg={'blue.400'}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}>
                    <Spinner />
                  </Button>
                  :
                  <Button
                    loadingText="Loading"
                    size="lg"
                    onClick={handleVerifyAccountNumber}
                    bg={'blue.400'}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}>
                    Continue <BsArrowRight style={{ fontSize: '22px', marginLeft: '.7rem', fontWeight: '600' }} />
                  </Button>
                }
              </Stack>
            }

            {verified && <>

              <label>Account Name</label>
              <InputContainer style={{ margin: '1.2rem 0' }}>

                <input
                  style={{"flex": "1"}}
                  type="text"
                  value={accountName}
                  placeholder='Account Name'
                />
              </InputContainer>

              <label>Amount</label>
              <InputContainer style={{ margin: '1.2rem 0' }}>
                <img src={naira} alt={'icon'} />
                <input
                style={{"flex": "1"}}
                  type="text"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder='6,000'
                />
              </InputContainer>

              <label>Description</label>
              <InputContainer style={{ margin: '1.2rem 0' }}>
                <input
                style={{"flex": "1"}}
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder='Transfer Description'
                />
              </InputContainer>

              <label>Password</label>
              <InputContainer style={{ margin: '1.2rem 0', justifyContent: 'space-between' }}>
                <InputGroup p={0} m={0}>
                  <Input 
                    style={{"flex": "1"}}
                    type={showPassword ? 'text' : 'password'} 
                    placeholder="Password"
                    value={password}
                    _focus={{
                      border: 'none',
                      outline: 'none',
                      background: 'transparent'
                    }}
                    onChange={(e) => setPassword(e.target.value)}
                    variant='unstyled'
                    required />
                  <InputRightElement h={'full'}>
                    <Button
                      variant={'ghost'}
                      _focus={{
                        border: 'none',
                        outline: 'none',
                        background: 'transparent'
                      }}
                      _hover={{
                        background: 'transparent'
                      }}
                      onClick={() => setShowPassword((showPassword) => !showPassword)}>
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </InputContainer>

              <Stack spacing={10} pt={2}>
                {isLoading() ?
                  <Button
                    loadingText="Loading"
                    size="lg"
                    border='none'
                    bg={'blue.400'}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}>
                    <Spinner />
                  </Button>
                  :
                  <Button
                    loadingText="Loading"
                    size="lg"
                    type='submit'
                    border='none'
                    bg={'blue.400'}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}>
                    Continue <BsArrowRight style={{ fontSize: '22px', marginLeft: '.7rem', fontWeight: '600' }} />
                  </Button>
                }

              </Stack>
            </>}
          </form>
        </Container>

      </Wrapper>
    </div>
  )
}

export default Airtime

const Wrapper = styled.div`
    background: #F3F3F3;
    padding-bottom: 4rem;
`;

const InputContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;

    height: 60px;
    
    border-radius: 12px;
    border: 1px solid ${colors.textColor};
    background: #F5F7FB;
    box-shadow: 0px 4px 120px 0px rgba(127, 135, 158, 0.10);
    
    padding: 8px 10px;
    margin-bottom: 1rem;
  
  h3 {
    background: rgba(66, 164, 217, .16);
    color: ${colors.primaryColor};
    border-radius: 15px;
    width: 55px;
    margin-right: .6rem;
    /* opacity: 0.16; */
    padding: 10px 14px;

    @media (max-width: 992px) {
      padding: 9px 12px;
    }

  }
  
  input {
    background: transparent;
  }
  input:focus {
    border: none;
    outline: none;
  }

  img {
    align-self: center;
    margin: 0 1rem;
  }
`;