import React, { useState, useEffect, useRef } from 'react'
import SubNav from '../../../components/sub-nav';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Container, Heading, Select, Stack, Button, Text, Tabs, TabList, TabPanels, Tab, TabPanel,
  useDisclosure,
  Grid,
  Flex,
  InputGroup, Input, InputRightElement, Spinner, Box, VStack
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { useToast } from '@chakra-ui/react'
import { phone, user } from '../../../assets/auth';
import { colors } from '../../../styles/theme';
import { styled } from 'styled-components';
import { BsArrowRight } from 'react-icons/bs'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { decrement, increment } from '../../../store/reducers/loading';
import SearchableDropdown from '../../../components/SearchableDropdown';
import { CurrencyFormatter } from '../../../utils/common';

const DataRecharge = () => {
  const loadingCount = useSelector((state) => state.loading.count);
  const dispatch = useDispatch();
  const isLoading = () => {
    // console.log(loadingCount);
    return loadingCount > 0;
  }
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate();
  const toast = useToast()
  const finalRef = React.useRef(null)

  const [bundleId, setBundleId] = useState('')
  const [number, setNumber] = useState('')
  const [password, setPassword] = useState('')
  const [networks, setNetworks] = useState([]);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('')
  const [networkId, setNetworkId] = useState('');
  const [beneficiaries, setBeneficiaries] = useState([])
  const [showPassword, setShowPassword] = useState(false)
  const [selectedBeneficiaryId, setSelectedBeneficiaryId] = useState(null)

  const [networkInformation, setNetworkInformation] = useState("");

  const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/v1/sme/${networkId}/get_bundles`;

  const handleGetData = async () => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/sme/get_network`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if (response.status == 401) {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if (response.status > 300) {
        if (response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      setNetworks(data.data.map(x => ({ value: x.id, label: x.name })))
      // // console.log(data)

    } catch (err) {
      dispatch(decrement());
      // Handle errors
      // console.log(err)
      toast({
        title: "Netork Error",
        status: 'error',
        description: "Check you internet connection",
        isClosable: true,
      })
    }
  }

  const handleGetBeneficiary = async () => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/sme/get_numbers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if (response.status == 401) {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if (response.status > 300) {
        if (response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      setBeneficiaries(data.data)


    } catch (err) {
      dispatch(decrement());
      // Handle errors
      // console.log(err)
      toast({
        title: "Netork Error",
        status: 'error',
        description: "Check you internet connection",
        isClosable: true,
      })
    }
  }

  const handleSelectedBeneficiary = async (selectedOption) => {

    let selectedBeneficiaryId = selectedOption;
    setSelectedBeneficiaryId(selectedBeneficiaryId);
    if (selectedBeneficiaryId == 0) return;
    if (!selectedBeneficiaryId || selectedBeneficiaryId.length < 1) return;
    let beneficiary = beneficiaries.filter(x => x.id == selectedBeneficiaryId)[0];
    setNetworkId(beneficiary.network.id);
    setNumber(beneficiary.phone_number);
    fetchData(beneficiary.network.id);

  }


  const fetchData = async (networdIdLocal) => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/sme/${networdIdLocal}/get_bundles`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if (response.status == 401) {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if (response.status > 300) {
        if (response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      // setPlans(data.data);

      setPlans(data.data.map(x => ({ value: x.id, label: x.name + " - " + CurrencyFormatter(x.amount) })))

    } catch (error) {
      dispatch(decrement());
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 401) {
        // Redirect to the login page if the API returns a 401 error
        navigate("/auth/login");
      }
    }
  };

  const changeNetworkInformation = (networkId) => {
    let label = networks.filter(x => x.value == networkId)[0].label;
    console.log(label.toUpperCase());
    switch(label.toUpperCase()) {
      case "MTN":
        setNetworkInformation("Dial *461*4# to check sme data");
      break;
      case "GLO":
        setNetworkInformation("Dial *127*0# to check data");
      break;
      case "AIRTEL":
        setNetworkInformation("Dial *323# to check data");
      break;
      case "9MOBILE":
        setNetworkInformation("Dial *228# to check data");
      break;
    }
  }


  const handleSelectedNetwork = (selectedOption) => {
    const selectedId = selectedOption;
    // console.log(selectedId)
    setNetworkId(selectedId);
    fetchData(selectedId);
    changeNetworkInformation(selectedId);
  };

  const handleSelectedPlan = (selectedOption) => {
    const selectedId = selectedOption;
    // setSelectedPlan(title)
    setBundleId(selectedId)
    // console.log(bundleId)
    onClose()
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!number && !password) {
      return toast.error("please provide all values");
    }
    const token = `Bearer ${Cookies.get('Token')}`
    try {
      dispatch(increment());
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/sme/vend`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          sme_network_bundle_id: bundleId,
          phone_number: number,
          payment_method: 'wallet',
          password: password,
        }), // Convert the form data to JSON
      });

      if (response.status == 401) {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if (response.status > 300) {
        if (response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      if (!data.status) {
      
        navigate("/dashboard");
        toast({
          title: "An error occurred",
          status: 'error',
          description: data?.message,
          isClosable: true,
        })
      } else {
        const msgToPass = "You data bundle purchase was successful!"
        navigate("/dashboard/success", { state: { type: 'sme', data: msgToPass, transaction: data.data } });

      }
    } catch (err) {
      dispatch(decrement());
      // Handle errors
      // console.log(err)
      navigate("/dashboard");
      toast({
        title: "Netork Error",
        status: 'error',
        description: "Check you internet connection",
        isClosable: true,
      })
    }

    // // console.log(user);
  };

  const getBeneficiaryOptions = () => {

    let options = beneficiaries.map(x => {
      return {
        value: x.id,
        label: x.phone_number
      }
    });

    return [{ value: 0, label: 'New beneficiary' }, ...options];

  }

  const filterOutName = (name) => {

  }

  useEffect(() => {
    handleGetData();
    handleGetBeneficiary();
  }, []);

  return (
    <div>
      <Wrapper>
        <SubNav title={'Cheap Data'} />
        <Container maxW={{ base: 'sm', md: 'md', lg: 'lg' }} my='2rem'>
          <Heading fontSize={'24px'} fontWeight='700' fontFamily={'inter'}>Buy Cheap Data With Ease</Heading>

          <form onSubmit={handleSubmit}>

            <label>Select Beneficiary</label>
            <InputContainer style={{ margin: '1.2rem 0', justifyContent: 'space-between' }}>
              <SearchableDropdown options={getBeneficiaryOptions()} onSelect={handleSelectedBeneficiary} defaultText={'Select Beneficiary'} value={selectedBeneficiaryId} />
            </InputContainer>

            <label>Select Network</label>
            <InputContainer style={{ margin: '1.2rem 0', justifyContent: 'space-between' }}>
              <SearchableDropdown options={networks} onSelect={handleSelectedNetwork} defaultText={'Select Network'} value={networkId} />
            </InputContainer>

            <Box>
            
              <Text color={'blue'}> {networkInformation}</Text>
             

            </Box>

            <label>Enter phone number</label>
            <InputContainer style={{ margin: '1.2rem 0', justifyContent: 'space-between' }}>
              <img src={phone} alt={'icon'} />
              <input
                style={{ "flex": "1" }}
                type={'text'}
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                required
              />
            </InputContainer>

            <label>Select Plan</label>
            <InputContainer style={{ margin: '1.2rem 0', justifyContent: 'space-between' }}>
              <SearchableDropdown options={plans} onSelect={handleSelectedPlan} defaultText={'Select Plan'} value={bundleId} />
            </InputContainer>

            <label>Pay with</label>
            <InputContainer style={{ margin: '1.2rem 0' }}>
              <Select variant={'unstyled'} >
                <option
                  value={'wallet'}
                >My Wallet </option>
              </Select>
            </InputContainer>



            <label>Password</label>
            <InputContainer style={{ margin: '1.2rem 0', justifyContent: 'space-between' }}>
              <InputGroup p={0} m={0}>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  variant='unstyled'
                  required />
                <InputRightElement h={'full'}>
                  <Button
                    variant={'ghost'}
                    _focus={{
                      border: 'none',
                      outline: 'none',
                      background: 'transparent'
                    }}
                    _hover={{
                      background: 'transparent'
                    }}
                    onClick={() => setShowPassword((showPassword) => !showPassword)}>
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </InputContainer>

            <Stack spacing={10} pt={2}>
              {isLoading() ?
                <Button
                  loadingText="Loading"
                  type='submit'
                  size="lg"
                  border='none'
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}>
                  <Spinner />
                </Button>
                :
                <Button
                  loadingText="Loading"
                  type='submit'
                  size="lg"
                  border='none'
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}>
                  Continue <BsArrowRight style={{ fontSize: '22px', marginLeft: '.7rem', fontWeight: '600' }} />
                </Button>
              }

            </Stack>
          </form>
        </Container>

      </Wrapper>
    </div>
  )
}

export default DataRecharge



const Wrapper = styled.div`
    background: #F3F3F3;
    padding-bottom: 4rem;
`;

const InputContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;

    height: 60px;
    
    border-radius: 12px;
    border: 1px solid ${colors.textColor};
    background: #F5F7FB;
    box-shadow: 0px 4px 120px 0px rgba(127, 135, 158, 0.10);
    
    padding: 8px 10px;
    margin-bottom: 1rem;
  
  h3 {
    background: rgba(66, 164, 217, .16);
    color: ${colors.primaryColor};
    border-radius: 15px;
    width: 55px;
    margin-right: .6rem;
    /* opacity: 0.16; */
    padding: 10px 14px;

    @media (max-width: 992px) {
      padding: 9px 12px;
    }

  }
  
  input {
    background: transparent;
  }
  input:focus {
    border: none;
    outline: none;
  }

  img {
    align-self: center;
    margin: 0 1rem;
  }

  svg {
    font-size: 18px;
    font-weight: 600;
  }
`;